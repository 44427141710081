<template>
  <div class="GiftPage">
    <div class="GiftPage-head">
      <router-link to="/" class="GiftPage-back">
        &lt;
      </router-link>
      <div v-if="gift" class="GiftPage-title">
        {{ gift.title }}
      </div>
      <img src="@/assets/logo.png" alt="Gift" class="GiftPage-logo">
    </div>
    <div class="GiftPage-content">
      <steps
        v-if="passedSteps.length"
        :steps="passedSteps"
        :default-step="getLastPassedStepIndex"
        class="GiftPage-steps"
      />
      <div v-else class="GiftPage-noSteps">
        Oups ... Nous n'avons pas encore d'information sur ton cadeau ... Mais nous avons mis les meilleurs enquêteurs sur le sujet !
        <br><br>
        Reviens nous voir bientôt !
      </div>
      <div v-if="nextStep" class="GiftPage-next">
        Prochaine mise à jour dans <countdown :deadline="nextStep.date" class="GiftPage-nextCountdown" @timeout="loadDatas" />
      </div>
    </div>
  </div>
</template>

<script>
  import GiftService from '@/services/gift.service';
  import Countdown from "../components/Countdown.vue";
  import Steps from "../components/Steps.vue";

  export default {
    name: 'Gift',

    components: {
      Countdown,
      Steps
    },

    data () {
      return {
        gift: null,
        nextStep: null,
        passedSteps: [],
        lastPassedIndex: 0
      }
    },

    computed: {
      getLastPassedStepIndex () {
        return this.passedSteps.length - 1 || 0;
      }
    },

    methods: {
      redirectLogin () {
        this.$router.push('/');
      },
      async loadDatas () {
        const code = this.$route && this.$route.params && this.$route.params.code;

        if (!code) {
          return this.redirectLogin();
        }

        try {
          this.gift = await GiftService.getGift(code);
        } catch (e) {
          return this.redirectLogin();
        }

        if (!this.gift) {
          return this.redirectLogin();
        }

        this.nextStep = await GiftService.getNextStepForGift(code);
        this.passedSteps = await GiftService.getPassedStepsForGift(code);
      }
    },

    created () {
      this.loadDatas();
    }
  }
</script>

<style lang="scss">
  .GiftPage {
    @include Page();
    background: $color-GiftPrimary;
    justify-content: flex-start;

    &-back {
      @include Round();
    }

    &-head {
      @include Container();
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2rem;
      background: rgba($color-Secondary, 0.3);
      border-radius: 3rem;
    }

    &-logo {
      max-height: 2.5rem;
    }

    &-content {
      @include Container();
      margin-top: 2rem;
      font-size: 1.2rem;
      color: $color-White;
      text-align: justify;
    }

    &-title {
      font-size: 1.4rem;
      font-weight: 600;
      color: $color-White;
      text-align: center;
    }

    &-next {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      margin-top: 2rem;
      border-radius: 1rem;
      font-size: 1.2rem;
      color: $color-White;
      background-color: rgba($color-White, 0.2);

      &Countdown {
        margin-left: 0.4rem;
      }
    }

    &-noSteps {
      text-align: center;
    }
  }
</style>
