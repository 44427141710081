<template>
  <div class="Steps">
    <div class="Steps-head">
      <button
        type="button"
        class="Steps-nav Steps-nav--previous"
        :class="{'Steps-nav--visible': hasPreviousStep}"
        @click="() => moveStep(-1)"
      >
        &lt;
      </button>
      <div v-if="currentStep" class="Steps-title">
        {{ currentStep.title }}

        <p class="Steps-date">
          Le {{ getFormattedDate(currentStep.date) }}
        </p>
      </div>
      <button
        type="button"
        class="Steps-nav Steps-nav--next"
        :class="{'Steps-nav--visible': hasNextStep}"
        @click="() => moveStep(1)"
      >
        &gt;
      </button>
    </div>
    <div class="Steps-current">
      <transition name="slide">
        <div v-if="currentStep && show">
          <img
            v-if="currentStep.image_src"
            :src="currentStep.image_src"
            class="Steps-currentImage"
          />
          <div v-html="currentStep.description" class="Steps-currentDescription" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Steps',

    props: {
      steps: {
        type: Array,
        required: true
      },
      defaultStep: {
        type: Number,
        default: 0
      }
    },

    data () {
      return {
        step: this.defaultStep,
        show: true
      }
    },

    computed: {
      currentStep () {
        return this.steps && this.steps[this.step];
      },
      hasPreviousStep () {
        return this.step > 0;
      },
      hasNextStep () {
        return this.steps.length - 1 > this.step;
      }
    },

    methods: {
      moveStep (val) {
        this.step += val;
        this.show = false;

        setTimeout(() => {
          this.show = true;
        }, 500)
      },
      getFormattedDate (value) {
        const date = new Date(value);

        return `${("0" + date.getDate()).slice(-2)}/${("0" + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`;
      }
    }
  }
</script>

<style lang="scss">
  .Steps {
    &-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-nav {
      @include Round(2rem);
      background: $color-White;
      color: $color-Black;
      cursor: pointer;
      opacity: 0;
      z-index: -1;
      transition: opacity .2s ease-in-out;

      &--visible {
        opacity: 1;
        z-index: 0;
      }
    }

    &-date {
      margin: 0;
      font-size: 0.8rem;
      font-weight: 400;
    }

    &-current {
      margin-top: 1rem;

      &Image {
        max-width: 100%;
        margin: 1rem 0;
        border-radius: 1rem;
      }

      &Description {
        & audio {
          height: 3rem;

          &::-webkit-media-controls-current-time-display,
          &::-webkit-media-controls-time-remaining-display, {
            font-size: 1rem;
          }

          &::-webkit-media-controls-mute-button,
          &::-internal-media-controls-overflow-button {
            background-size: 1.5rem;
          }
        }
      }
    }

    &-title {
      font-weight: 600;
    }
  }

  .slide-enter-active, .slide-leave-active {
    transition: all .5s;
  }
  .slide-enter, .slide-leave-to {
    transform: translateX(-1rem);
    opacity: 0;
  }
</style>
