<template>
  <div v-if="current" class="Countdown">
    {{ getCurrent }}
  </div>
</template>

<script>
  export default {
    name: 'Countdown',

    props: {
      deadline: {
        type: String,
        required: true
      },
      speed: {
        type: Number,
        default: 1000
      }
    },

    data () {
      return {
        timer: null,
        current: null
      }
    },

    created() {
      this.start();
    },

    beforeUnmount() {
      this.stop();
    },

    computed: {
      getCurrent () {
        if (!this.current.days) {
          return this.current
            ? `${('0' + this.current.hours).slice(-2)}h${(
              '0' + this.current.minutes
            ).slice(-2)}:${('0' + this.current.seconds).slice(-2)}`
            : '';
        }

        return this.current
          ? `${this.current.days} ${this.current.days > 1 ? 'jours': 'jour'} et ${('0' + this.current.hours).slice(-2)}h${(
            '0' + this.current.minutes
          ).slice(-2)}:${('0' + this.current.seconds).slice(-2)}`
          : '';
      }
    },

    methods: {
      countdown() {
        let t = Date.parse(this.deadline) - Date.parse(new Date());
        let seconds = Math.floor((t / 1000) % 60);
        let minutes = Math.floor((t / 1000 / 60) % 60);
        let hours = Math.floor((t / (1000 * 60 * 60)) % 24);
        let days = Math.floor(t / (1000 * 60 * 60 * 24));
        if (t > 0) {
          this.current = {
            total: t,
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds
          };
          setTimeout(this.countdown, this.speed);
        } else {
          this.current = null;
          this.$emit('timeout')
        }
      },
      start () {
        if (this.timer) {
          this.stop();
        }
        this.timer = setTimeout(this.countdown, 1);
      },
      stop () {
        clearTimeout(this.timer);
      }
    },

    watch: {
      deadline: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.start();
        }
      }
    }
  }
</script>
